<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <span class="option_txt">조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="QTargetStartDate"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="QTargetEndtDate"
                @change="setEndDate($event)"
              />
            </div>
          </div>
        </div>
        <button type="button" class="btn_sub2" @click="goToProductQuality()">
          불량품 검사로 이동
        </button>
      </div>
      <h6>조회수 : {{ filteredKPI.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>규격</th>
              <th>생산개수</th>
              <th>불량개수</th>
              <th>불량률[PPM]</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredKPI" :key="index">
              <td>{{ findInfoFromId(products, item.product_id).name }}</td>
              <td>{{ $makeComma(item.total_quantity) }}</td>
              <td>{{ $makeComma(item.fail_quantity) }}</td>
              <td>{{ $makeComma(item.ppm) }}</td>
            </tr>
            <tr class="total">
              <td>총합</td>
              <td>{{ $makeComma(makeTotalData.total_quantity) }}</td>
              <td>{{ $makeComma(makeTotalData.fail_total_quantity) }}</td>
              <td>{{ $makeComma(makeTotalData.total_ppm) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="rate_explan">불량률 [PPM] = 불량개수 / 생산개수 * 1,000,000</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routes from '@/routes/routes';
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import { yyyymmdd } from '@/utils/func';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      products: 'getVisibleProduct',
      QTargetStartDate: 'getQTargerStartDateFromKpiTarget',
      QTargetEndtDate: 'getQTargerEndDateFromKpiTarget',
      checkLists: 'getCheckListFromProductVerification',
    }),
    filteredKPI() {
      if (this.checkLists.length > 0) {
        let productCheckList = this.lodash
          .clonedeep(this.checkLists)
          .filter(x =>
            this.findInfoFromId(this.products, x.product_id).name.includes(
              '점적 Chip K',
            ),
          );

        const productGroup = productCheckList.reduce(function(rv, x) {
          (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
          return rv;
        }, {});

        let producTotalList = [];
        for (let item in productGroup) {
          console.log('item', item);
          const totalQuantity = productGroup[item]
            .map(x => {
              if (x.pass_quantity != null) {
                const calQuantity = this.$decimalAdd(
                  x.pass_quantity,
                  x.unpass_quantity,
                );
                return calQuantity;
              } else return 0;
            })
            .reduce((a, b) => this.$decimalAdd(a, b), 0);
          const failQuantity = productGroup[item]
            .map(x => {
              return x.unpass_quantity != null ? x.unpass_quantity : 0;
            })
            .reduce((a, b) => this.$decimalAdd(a, b), 0);
          producTotalList.push({
            product_id: item,
            total_quantity: totalQuantity,
            fail_quantity: failQuantity,
            ppm: this.$decimalMul(
              this.$decimalDiv(failQuantity, totalQuantity),
              1000000,
            ).toFixed(2),
          });
        }

        return producTotalList;
      } else return [];
    },
    makeTotalData() {
      if (this.filteredKPI.length > 0) {
        const totalQuantity = this.lodash
          .clonedeep(this.filteredKPI)
          .map(x => x.total_quantity)
          .reduce((a, b) => this.$decimalAdd(a, b), 0);
        const failTotalQuantity = this.lodash
          .clonedeep(this.filteredKPI)
          .map(x => x.fail_quantity)
          .reduce((a, b) => this.$decimalAdd(a, b), 0);
        const totalPpm =
          failTotalQuantity == 0
            ? 0
            : this.$decimalMul(
                this.$decimalDiv(failTotalQuantity, totalQuantity),
                1000000,
              ).toFixed(2);

        return {
          total_quantity: totalQuantity,
          fail_total_quantity: failTotalQuantity,
          total_ppm: totalPpm,
        };
      } else {
        return {
          total_quantity: 0,
          fail_total_quantity: 0,
          total_ppm: 0,
        };
      }
    },
  },
  methods: {
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.QTargetEndtDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.QTargetEndtDate})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.QTargetStartDate;
        } else {
          this.$store.commit('setQTargerStartDateToKpiTarget', e.target.value);
          await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
            'FETCH_PRODUCT_VERIFICATION_DEFECT',
            {
              start: this.QTargetStartDate,
              end: this.QTargetEndtDate,
            },
            '제품검사',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setQTargerStartDateToKpiTarget',
          yyyymmdd(new Date()),
        );
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.QTargetStartDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.QTargetStartDate})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.QTargetEndtDate;
        } else {
          this.$store.commit('setQTargerEndDateToKpiTarget', e.target.value);
          await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
            'FETCH_PRODUCT_VERIFICATION_DEFECT',
            {
              start: this.QTargetStartDate,
              end: this.QTargetEndtDate,
            },
            '제품검사',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setQTargerEndDateToKpiTarget',
          yyyymmdd(new Date()),
        );
      }
    },
    goToProductQuality() {
      const goingPage = routes[0].children.find(
        x => x.path == '/quality/product',
      );

      if (goingPage != undefined) {
        goingPage.meta.tab = 0;
      }

      this.$router.push('/quality/product');
    },
  },
  async created() {
    this.showSpinner();

    if (this.Q_target_start_date == null) {
      const date = new Date();
      this.$store.commit('setQTargerEndDateToKpiTarget', yyyymmdd(date));
      const date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setQTargerStartDateToKpiTarget', yyyymmdd(date2));
    }

    if (this.products.length == 0) {
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT', '제품');
    }

    if (this.checkLists.length < 1) {
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_PRODUCT_VERIFICATION_DEFECT',
        {
          start: this.QTargetStartDate,
          end: this.QTargetEndtDate,
        },
        '제품검사',
      );
    }

    this.hideSpinner();
  },
};
</script>
