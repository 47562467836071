<template>
  <div id="contents" class="kpi">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li class="active">
            <a href="#" @click.prevent="">KPI 완제품 불량률</a>
          </li>
        </ul>
      </div>
      <KPIDefectiveRateForm />
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import KPIDefectiveRateForm from '@/views/forms/Custom/Monitor/19/KPIDefectiveRateForm';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: { KPIDefectiveRateForm },
  // created() {
  //   if (this.$route.meta.menu_init == true) {
  //     this.$store.commit('InitKPIPage');
  //     this.$route.meta.menu_init = false;
  //   }
  // },
};
</script>

<style lang="scss">
@import 'taegwang_monitoring';
</style>
